var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "py-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label",
    attrs: {
      "text": "",
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "font-weight-regular secondary--text text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_users_create_title")) + " ")])])], 1)], 1)], 1), _c('v-divider'), _c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-card-text', {
    staticClass: "custom-card-text px-0"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "align-self": "center",
      "cols": "2",
      "md": "4"
    }
  }, [_vm._v("ID")]), _c('v-col', {
    staticClass: "label-text",
    attrs: {
      "align-self": "center",
      "cols": "4",
      "md": "4"
    }
  }, [_vm._v(_vm._s(_vm.getNextUserNumber))]), _c('v-col', {
    attrs: {
      "align-self": "center",
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-avatar', {
    staticClass: "white--text avatar-border",
    attrs: {
      "color": "avatar-bg-grey",
      "size": "38"
    }
  }, [!_vm.selectedFile ? _c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("$user")]) : _c('v-img', {
    attrs: {
      "src": !_vm.selectedFile ? _vm.file : _vm.selectedFile,
      "width": "40"
    }
  })], 1), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.isSelecting
    },
    on: {
      "click": _vm.handleFileImport
    }
  }, [_c('span', {
    staticClass: "plus"
  }, [_vm._v("+")]), _vm._v("画像ファイル ")]), _c('input', {
    ref: "uploader",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.onFileChanged
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.fields, function (field) {
    return _c('v-col', {
      key: field.name,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "4",
        "md": "4",
        "align-self": "baseline"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(field.label) + " ")]), _c('span', {
      staticClass: "required-text ml-1"
    }, [_vm._v("必須")])]), field.type === 'text' || field.type === 'email' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "align-self": "baseline"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "placeholder": field.placeholder,
              "autocomplete": "chrome-off",
              "error-messages": errors
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1), field.additional_field ? _c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "offset": "4",
        "offset-md": "0"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.additional_field.name,
        "rules": field.additional_field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "placeholder": field.additional_field.placeholder,
              "error-messages": errors,
              "autocomplete": "chrome-off"
            },
            model: {
              value: field.additional_field.value,
              callback: function callback($$v) {
                _vm.$set(field.additional_field, "value", $$v);
              },
              expression: "field.additional_field.value"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e()] : _vm._e()], 2)], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._l(_vm.fieldsRight, function (field) {
    return _c('v-row', {
      key: field.name,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "3",
        "md": "3",
        "align-self": "baseline"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(field.label) + " ")]), _c('span', {
      staticClass: "required-text ml-1"
    }, [_vm._v("必須")])]), field.type === 'text' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "align-self": "baseline"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "placeholder": field.placeholder,
              "autocomplete": "off",
              "error-messages": errors
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1)] : _vm._e()], 2);
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "cols": "3",
      "md": "3",
      "align-self": "baseline"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("password")) + " ")]), _c('span', {
    staticClass: "required-text ml-1"
  }, [_vm._v("必須")])]), _c('v-col', {
    attrs: {
      "cols": "8",
      "align-self": "baseline"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": _vm.passwordRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "flat": "",
            "type": _vm.inputPassword ? 'password' : 'text',
            "placeholder": "パスワード再設定用のメールを送信する",
            "append-icon": "mdi-eye",
            "error-messages": errors,
            "autocomplete": "chrome-off"
          },
          on: {
            "click:append": _vm.toggleInputPassword
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "info-card",
    attrs: {
      "flat": "",
      "color": "#f9f5e9"
    }
  }, [_c('v-card-text', {
    attrs: {
      "flat": "",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_text")) + " ")]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_first_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsCorrectLength ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsCorrectLength ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_second_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsUppercase ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsUppercase ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_third_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsLowercase ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsLowercase ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_fourth_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsNumber ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsNumber ? "mdi-check" : "mdi-close") + " ")])], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.passwordStrength >= 1 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-weak",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e(), _vm.passwordStrength >= 2 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-medium",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e(), _vm.passwordStrength >= 3 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-strong",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e()], 1)], 1), _vm.passwordStrength >= 1 ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    "class": "password-".concat(_vm.passwordStrengthText, "-text")
  }, [_vm._v(" " + _vm._s(_vm.$t("your_password_is", {
    strength: _vm.passwordStrengthText
  })) + " ")])]) : _vm._e()], 1)], 1)], 1)], 2), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "type": "submit",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus,
      "color": "primary",
      "depressed": "",
      "small": "",
      "width": "90"
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }