<template>
  <v-container fluid>
    <UserHeader></UserHeader>
    <UserForm></UserForm>
  </v-container>
</template>

<script>
import UserHeader from "../components/UserHeader";
import UserForm from "../components/UserForm";
export default {
  name: "UserCreate",
  components: { UserHeader, UserForm },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
