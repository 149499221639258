<template>
  <v-row class="mb-2">
    <v-col cols="auto">
      <div class="fw-700 font-20px">
        <slot></slot>
      </div>
    </v-col>

    <v-spacer></v-spacer>

    <v-col cols="auto">
      <v-btn
        small
        class="text-capitalize font-weight-regular"
        text
        @click="$router.push({ name: 'UserList' })"
      >
        <v-icon color="secondary" left>$goBack</v-icon>
        {{ $t("go_back") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn small text>
        <v-icon color="secondary">$more</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UserHeader"
};
</script>
