<template>
  <v-card>
    <v-card-title class="py-5">
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t("page_users_create_title") }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit" autocomplete="off">
        <v-card-text class="custom-card-text px-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col
                    align-self="center"
                    cols="2"
                    md="4"
                    class="text-right label-text"
                    >ID</v-col
                  >
                  <v-col
                    align-self="center"
                    cols="4"
                    md="4"
                    class="label-text"
                    >{{ getNextUserNumber }}</v-col
                  >
                  <v-col align-self="center" cols="12" md="4">
                    <v-avatar
                      color="avatar-bg-grey"
                      size="38"
                      class="white--text avatar-border"
                    >
                      <v-icon v-if="!selectedFile" size="18">$user</v-icon>
                      <v-img
                        v-else
                        :src="!selectedFile ? file : selectedFile"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <v-btn
                      text
                      color="primary"
                      class="text-capitalize"
                      :loading="isSelecting"
                      @click="handleFileImport"
                    >
                      <span class="plus">+</span>画像ファイル
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col
                            cols="4"
                            md="4"
                            align-self="baseline"
                            class="text-right label-text"
                          >
                            <span>
                              {{ field.label }}
                            </span>
                            <span class="required-text ml-1">必須</span>
                          </v-col>

                          <template
                            v-if="
                              field.type === 'text' || field.type === 'email'
                            "
                          >
                            <v-col
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              align-self="baseline"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :placeholder="field.placeholder"
                                  autocomplete="chrome-off"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="field.additional_field"
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              offset="4"
                              offset-md="0"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.additional_field.name"
                                :rules="field.additional_field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="field.additional_field.value"
                                  :placeholder="
                                    field.additional_field.placeholder
                                  "
                                  :error-messages="errors"
                                  autocomplete="chrome-off"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row
                      align="center"
                      v-for="field in fieldsRight"
                      :key="field.name"
                    >
                      <v-col
                        cols="3"
                        md="3"
                        align-self="baseline"
                        class="text-right label-text"
                      >
                        <span>
                          {{ field.label }}
                        </span>
                        <span class="required-text ml-1">必須</span>
                      </v-col>

                      <template v-if="field.type === 'text'">
                        <v-col
                          cols="8"
                          :md="field.additional_field ? '4' : '8'"
                          align-self="baseline"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="field.name"
                            :rules="field.rules"
                          >
                            <v-text-field
                              outlined
                              dense
                              flat
                              :type="field.type"
                              v-model="field.value"
                              :placeholder="field.placeholder"
                              autocomplete="off"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </template>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="12">
                        <v-row align="center">
                          <v-col
                            cols="3"
                            md="3"
                            align-self="baseline"
                            class="text-right label-text"
                          >
                            <span>
                              {{ $t("password") }}
                            </span>
                            <span class="required-text ml-1">必須</span>
                          </v-col>
                          <v-col cols="8" align-self="baseline">
                            <validation-provider
                              v-slot="{ errors }"
                              name="password"
                              :rules="passwordRules"
                            >
                              <v-text-field
                                outlined
                                dense
                                flat
                                :type="inputPassword ? 'password' : 'text'"
                                v-model="password"
                                placeholder="パスワード再設定用のメールを送信する"
                                append-icon="mdi-eye"
                                @click:append="toggleInputPassword"
                                :error-messages="errors"
                                autocomplete="chrome-off"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="8" offset-md="3">
                        <v-row>
                          <v-col cols="12">
                            <v-card class="info-card" flat color="#f9f5e9">
                              <v-card-text flat elevation="0">
                                <div class="mb-2">
                                  {{
                                    $t("page_users_create_password_hint_text")
                                  }}
                                </div>
                                <ul>
                                  <li>
                                    {{
                                      $t(
                                        "page_users_create_password_hint_first_rule_text"
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsCorrectLength
                                          ? 'success'
                                          : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsCorrectLength
                                          ? "mdi-check"
                                          : "mdi-close"
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        "page_users_create_password_hint_second_rule_text"
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsUppercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsUppercase
                                          ? "mdi-check"
                                          : "mdi-close"
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        "page_users_create_password_hint_third_rule_text"
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsLowercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsLowercase
                                          ? "mdi-check"
                                          : "mdi-close"
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        "page_users_create_password_hint_fourth_rule_text"
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsNumber ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsNumber
                                          ? "mdi-check"
                                          : "mdi-close"
                                      }}
                                    </v-icon>
                                  </li>
                                  <!--<li>
                                    {{
                                      $t(
                                        "page_users_create_password_hint_fifth_rule_text"
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsSymbol ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsSymbol
                                          ? "mdi-check"
                                          : "mdi-close"
                                      }}
                                    </v-icon>
                                  </li>-->
                                </ul>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-row>
                              <v-col v-if="passwordStrength >= 1" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-weak"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 2" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-medium"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 3" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-strong"
                                ></v-btn>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            v-if="passwordStrength >= 1"
                            cols="12"
                            class="text-center"
                          >
                            <span
                              :class="`password-${passwordStrengthText}-text`"
                            >
                              {{
                                $t("your_password_is", {
                                  strength: passwordStrengthText
                                })
                              }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <v-btn
                      type="submit"
                      :disabled="formStatus"
                      :loading="formStatus"
                      color="primary"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                      width="90"
                    >
                      保存
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "index",
  computed: {
    ...mapGetters(["getNextUserNumber", "user"]),
    passwordStrength() {
      if (!this.password) return 0;
      let strong = RegExp(
        "^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
        "g"
      );
      let medium = new RegExp(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      );
      if (strong.test(this.password)) {
        return 3;
      } else if (medium.test(this.password)) {
        return 2;
      } else {
        return 1;
      }
    },
    passwordStrengthText() {
      return this.passwordStrength === 3
        ? this.$t("strong")
        : this.passwordStrength === 2
        ? this.$t("medium")
        : this.$t("weak");
    },
    containsCorrectLength() {
      return this.password.match(/(^.{8,20}$)/);
    },
    containsUppercase() {
      return this.password.match(/([A-Z])/);
    },
    containsLowercase() {
      return this.password.match(/([a-z])/);
    },
    containsNumber() {
      return this.password.match(/([0-9])/);
    },
    containsSymbol() {
      return this.password.match(/[^ A-Za-z0-9]/);
    }
  },
  data() {
    return {
      valid: false,
      inputPassword: true,
      password: "",
      passwordScore: 0,
      passwordRules: "required:パスワード|password",
      isSelecting: false,
      selectedFile: null,
      file: null,
      fields: [
        {
          label: "登録者名",
          name: "surname",
          type: "text",
          placeholder: "姓",
          value: "",
          rules: "required:姓",
          additional_field: {
            type: "text",
            name: "name",
            placeholder: "名",
            value: "",
            rules: "required:名"
          }
        },
        {
          label: "フリガナ",
          name: "furigana_surname",
          type: "text",
          placeholder: "フリガナ",
          value: "",
          rules: "required:フリガナ",
          additional_field: {
            type: "text",
            name: "furigana_name",
            placeholder: "メイ",
            value: "",
            rules: "required:メイ"
          }
        },
        {
          label: "電話番号",
          name: "telephone_number",
          type: "text",
          placeholder: "電話番号",
          value: "",
          rules: "required:電話番号|enter_half_width_numbers_hyphens"
        }
      ],
      fieldsRight: [
        {
          label: "会社名",
          name: "shop_name",
          type: "text",
          placeholder: "会社名",
          value: "",
          rules: "required:会社名"
        },
        {
          label: "役職",
          name: "position",
          type: "text",
          placeholder: "役職",
          value: "",
          rules: "required:役職"
        },
        {
          label: "メールアドレス(ID)",
          name: "email",
          type: "text",
          placeholder: "メールアドレス",
          value: "",
          rules: "email|required:メールアドレス"
        }
      ],
      selectedGroups: null,
      selectedBusiness: null,
      notificationEmail: false,
      passwordEmail: false,
      formStatus: false
    };
  },
  created() {
    this.$store.dispatch("NEXT_USER_NUMBER", {
      user_id: this.user.id
    });
  },
  methods: {
    toggleInputPassword() {
      this.inputPassword = !this.inputPassword;
    },
    async submit() {
      this.formStatus = true;
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        const data = new FormData();

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, "additional_field")) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            );
          }
          data.append(field.name, field.value);
        });

        this.fieldsRight.forEach(field => {
          if ({}.hasOwnProperty.call(field, "additional_field")) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            );
          }
          data.append(field.name, field.value);
        });

        data.append("avatar", this.file);
        data.append("password", this.password);

        this.$store
          .dispatch("CREATE_USER", data)
          .then(result => {
            if (result.status === 200) this.$refs.observer.reset();
            this.$store.dispatch("MASTER_GET_ALL").then(() => {
              this.$router.push({ name: "UserList" });
            });
          })
          .catch(error => {
            if (error.status == 422) {
              this.$refs.observer.setErrors(error?.data?.errors);
            }
          })
          .finally(() => (this.formStatus = true));
      });
    },
    // Avatar input code
    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = URL.createObjectURL(e.target.files[0]);
      this.file = e.target.files[0];
      // Do whatever you need with the file, liek reading it with FileReader
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
