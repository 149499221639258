var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-20px"
  }, [_vm._t("default")], 2)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'UserList'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("$goBack")]), _vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("$more")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }